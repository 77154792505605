import React from 'react'
// import parse from 'html-react-parser'
//{parse(`We Manage <span>The Complex</span>`)}
import GeneralPageLayout from '../components/Layouts/GeneralPage'
import TextImgSplit from '../components/Layouts/TextImgSplit/TextImgSplit'
import MainTextBlock from '../components/UI/MainTextBlock/MainTextBlock'
import SectionContainer from './../components/UI/Containers/SectionContainer'
import SectionHeader from './../components/UI/SectionHeader/SectionHeader'
import PulloutQuote from '../components/UI/Quote/PulloutQuote'
import './../styles/pageSpecific/whoWeAre.scss'

import destroyerAtSea from './../images/destroyerAtSeaS.jpg'
import Awards from '../components/UI/Awards/Awards'
import QualityStatement from '../components/UI/QualityStatement/QualityStatement'
import CharitableCollection from '../components/UI/Images/CharitableCollection/CharitableCollection'
import DataEm from '../components/UI/Data/DataEm/DataEm'
import VeteranProgramsList from '../components/UI/Images/VeteranProgramsList/VeteranProgramsList'
import VeteranBadge from './../images/icons/veteranMedal-white.png'
import VeteranPledgeSeal from './../images/icons/veteranPledge-seal.png'
import BannerCallout from '../components/Callouts/BannerCallout/BannerCallout'
import Partners from '../components/UI/Images/Partners/Partners'

const AboutPage = () => {
  const pageInfo = {
    pageType: 'interior',
    title1: 'Who',
    title2: 'We Are',
    breadcrumbs: [],
    ctaButton: false,
    ctaButtonData: {
      text: '',
      url: '',
    },
  }

  return (
    <GeneralPageLayout pageData={pageInfo}>
      <div className="content">
        {/* Intro */}
        <SectionContainer id="intro" color="white" type="pressedEdgeRight">
          <SectionHeader color="navy" alignment="left" size="med">
            We Manage <span>The Complex</span>
          </SectionHeader>
          <TextImgSplit
            imageData={destroyerAtSea}
            altInfo="destroy ship out at sea"
          >
            <MainTextBlock
              buttonData={{
                text: 'Our Culture',
                url: '/our-culture',
                type: 'colored',
              }}
              secondaryBtnData={{
                type: `gray`,
                url: '/our-history',
                text: 'Our History',
              }}
            >
              <p>
                Precise Systems is a professional services company supporting
                U.S. Department of Defense programs. Our core capabilities
                include Digital Transformation, Advanced Engineering,
                Acquisition & Lifecycle Support, Information Technology, and
                Technical Solutions. Precise was founded in 1990, and since our
                establishment, we have provided cutting-edge solutions and
                support to our warfighters. At Precise, we understand and
                provide expert consultation on network and weapons systems
                acquisition, maintenance/modernization, and sustainment
                programs.
              </p>

              <p>
                For over 30 years, Precise Systems has demonstrated and
                perfected our ability to manage any task, no matter how
                difficult or complex.
              </p>
            </MainTextBlock>
          </TextImgSplit>
        </SectionContainer>

        {/* Awards */}
        <SectionContainer
          id="awards"
          color="navy"
          type="fullWidth"
          classes={['inlineChildren_column', 'awardsNarrowContainer']}
        >
          <SectionHeader
            color="white"
            alignment="center"
            size="small"
            trim="true"
          >
            {/* tag='These accolades are testament to our proven success.' */}
            We are <span>Award Winning</span>
          </SectionHeader>
          <Awards />
        </SectionContainer>

        {/* Culture */}
        <SectionContainer
          id="culture"
          color="white"
          type="fullWidth"
          classes={['inlineChildren']}
        >
          <PulloutQuote color="colored" type="inline" alignment="left">
            The cornerstone of our culture is our commitment to{' '}
            <span>Our People</span>
          </PulloutQuote>
          <section className="card">
            <SectionHeader color="navy" alignment="left" size="med">
              Our Company <span>Culture</span>
            </SectionHeader>
            <MainTextBlock>
              <p>
                "Put Our People First" and "Delight the Customer" - these core
                values embody our employee-centric culture. We are proud of the
                services we provide and we are extremely fortunate to have a
                workforce comprised of outstanding, highly-skilled professionals
                who are motivated and focus their talent and efforts on the
                accomplishment of our customers' mission. Our culture uniquely
                positions the Precise Team to provide exceptional service to our
                direct customers in support of the warfighter.{' '}
              </p>
            </MainTextBlock>
          </section>
        </SectionContainer>

        {/* Veterans */}
        <SectionContainer id="veterans" color="navy" type="contained">
          <div className="veteranBadge">
            <img
              src={VeteranBadge}
              alt="Veteran Badge icon"
              width="78"
              height="129"
            />
          </div>
          <div className="veteranPledgeSeal">
            <img
              src={VeteranPledgeSeal}
              alt="Veteran Hiring Pledge Seal"
              width="84"
              height="84"
            />
          </div>
          <SectionHeader
            color="white"
            alignment="center"
            size="med"
            trim="true"
          >
            Commited to <span>Veterans</span>
          </SectionHeader>
          <section className="inlineChildren dataSection">
            <section className="textContent">
              <p>
                We pride ourselves in “service to our country and tradition” by
                honoring the legacy of those who have served - past, present,
                and future. Precise readily hires veterans of all service
                backgrounds to ensure an effortless transition into the civilian
                sector. As a veteran-owned business, we are committed to
                supporting service members and their families in and out of the
                workplace.{' '}
              </p>
            </section>
            <section className="dataContent">
              <DataEm
                total="5"
                title="Branches Represented"
                bg="dark"
                size="lg"
                stackText={true}
              />
              <DataEm
                total="46"
                title="Veteran Leadership"
                bg="dark"
                size="lg"
                type="percentage"
                stackText={true}
              />
            </section>
          </section>
          <section id="veteranPrograms" className="participantsSection">
            <SectionHeader
              color="white"
              alignment="center"
              size="xsmall"
              trim="true"
            >
              Proud <span>Participants</span>
            </SectionHeader>
            <VeteranProgramsList />
          </section>
        </SectionContainer>

        {/* Community */}
        <SectionContainer id="community" color="white" type="contained">
          <section className="community_wrap inlineChildren_tabletLG">
            <section>
              <SectionHeader color="navy" alignment="left" size="med">
                Precise in the <span>Community</span>
              </SectionHeader>
              <MainTextBlock>
                {/* buttonData={{text:'Explore Our Charitable Work', url:'/', type:'colored'}} */}
                <p>
                  Precise maintains a record of philanthropy and giving to local
                  and national causes. We currently support over 50 charities
                  and needs-driven organizations. We established a Precise
                  Systems Charitable Contributions Committee that convenes
                  quarterly to review employee recommended organizations. This
                  committee supports the Precise Systems Core Value of “Putting
                  People First”, by providing an opportunity for our company to
                  put people first by offering support to organizations in the
                  communities where our employees live and work.
                </p>
                {/* <section className='dataContent'>
                                    <DataEm total='100' title='Events' />
                                    <DataEm total='2000' title='Donated Hours' />
                                </section> */}
                <section className="dataContent tempdataContent inlineChildren_mobile">
                  <DataEm
                    size="displayMed"
                    total="100"
                    title="Events"
                    type="plus"
                  />
                  <DataEm
                    size="displayMed"
                    total="2000"
                    title="Donated Hours"
                    type="plus"
                  />
                </section>
              </MainTextBlock>
            </section>
            <CharitableCollection />
          </section>
        </SectionContainer>

        {/* CallOut */}
        <BannerCallout color="navy" />

        {/* Quality Statement */}
        <SectionContainer id="quality" color="gray" type="fullWidth">
          <QualityStatement />
        </SectionContainer>

        {/* Partners */}
        <SectionContainer id="partners" color="white" type="pressedEdges">
          <SectionHeader
            color="navy"
            alignment="center"
            size="small"
            trim="true"
          >
            Our <span>Partners</span>
          </SectionHeader>
          <Partners />
        </SectionContainer>
      </div>
    </GeneralPageLayout>
  )
}

export default AboutPage
